import React from 'react';

export function Play(props) {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx="24.504" cy="24.9051" rx="23.9605" ry="23.9605" fill="black" fillOpacity="0.2" />
      <path d="M36.3531 24.4552C36.7292 24.6373 36.7292 25.1731 36.3531 25.3552L19.431 33.5494C19.0989 33.7102 18.7131 33.4684 18.7131 33.0994L18.7131 16.711C18.7131 16.3421 19.0989 16.1002 19.431 16.261L36.3531 24.4552Z" fill="white" />
    </svg>

  );
}
