/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';

import { Chat } from './components/icons/Chat';
import { Email } from './components/icons/Email';
import { Eye } from './components/icons/Eye';
import { SadFace } from './components/icons/SadFace';
import { StyledBox } from './styles/Styled';

function SectionHelpCards({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  return (
    <StyledBox
      tag="section"
      paddingHorizontal="6x"
      paddingVertical={{ xs: '12x', md: '24x' }}
      display="grid"
      width={{ md: 'fit-content' }}
      gridGap="6x"
      margin={{ md: 'auto' }}
      gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
    >
      <Box gridRow={{ md: ' 1 / 4', lg: '1 / 3 ' }} gridColumn={{ lg: '1 / -1' }}>
        <Typography
          tag="h2"
          color="black"
          marginBottom="4x"
          variant="heading2"
          className="help-title"
          intlKey={`cms.securityPage.cardsArea[${index}].title`}
        />
        <Typography
          variant="subtitle1"
          tag="p"
          color="black"
          whiteSpace="pre-line"
          marginBottom="2x"
          className="help-subtitle"
          width={{ md: '330px', lg: '100%' }}
          intlKey={`cms.securityPage.cardsArea[${index}].subtitle`}
        />

      </Box>
      <Box
        backgroundColor="black.default"
        borderRadius="25px"
        className="help-card"
        height="100%"
        width={{ xs: '330px', lg: '380px' }}
        padding="6x"
        display="flex"
        flexDirection="column"
        gridGap="6x"
        gridColumn="1"
        margin="auto"
        gridRow={{ md: ' 4 / 10' }}
      >
        <Box
          height="50px"
          width="50px"
          borderRadius="50%"
          backgroundColor="white.default"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Chat aria-hidden="true" />
        </Box>
        <Typography
          tag="h4"
          className="help-card-title"
          color="white.default"
          variant="heading4"
          intlKey={`cms.securityPage.cardsArea[${index}].helpCards[0].title`}
        />
        <Typography tag="p" color="white.default" variant="paragraph1" intlKey={`cms.securityPage.cardsArea[${index}].helpCards[0].description`} />
      </Box>
      <Box
        backgroundColor="black.default"
        borderRadius="25px"
        className="help-card"
        height="100%"
        width={{ xs: '330px', lg: '100%' }}
        padding="6x"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gridGap="6x"
        gridColumn={{ md: '1', lg: '1 / span 2' }}
        gridRow={{ md: '10' }}
        margin="auto"
      >
        <Box
          height="50px"
          width="50px"
          borderRadius="50%"
          backgroundColor="white.default"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SadFace aria-hidden="true" />
        </Box>
        <Box display="flex" flexDirection="column" gridGap="6x">
          <Typography tag="h4" className="help-card-title" color="white.default" variant="heading4" intlKey={`cms.securityPage.cardsArea[${index}].helpCards[1].title`} />
          <Typography tag="p" color="white.default" variant="paragraph1" width={{ lg: '70%' }} intlKey={`cms.securityPage.cardsArea[${index}].helpCards[1].description`} />
          <Link
            intlKey={`cms.securityPage.cardsArea[${index}].helpCards[1].ctaTitle`}
            href={formatMessage({ id: `cms.securityPage.cardsArea[${index}].helpCards[1].ctaUrl` })}
            variant="action"
            className="help-card-cta"
            width="fit-content"
            typographyProps={{
              color: 'white.default',
            }}
          />
        </Box>
      </Box>
      <Box
        backgroundColor="black.default"
        borderRadius="25px"
        className="help-card"
        height="100%"
        width={{ xs: '330px', lg: '380px' }}
        padding="6x"
        display="flex"
        flexDirection="column"
        gridGap="6x"
        gridColumn={{ md: '2' }}
        gridRow={{ md: ' 1 / 7', lg: '4 / 10' }}
        margin="auto"
      >
        <Box
          height="50px"
          width="50px"
          borderRadius="50%"
          backgroundColor="white.default"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Eye aria-hidden="true" />
        </Box>
        <Typography tag="h4" className="help-card-title" color="white.default" variant="heading4" intlKey={`cms.securityPage.cardsArea[${index}].helpCards[2].title`} />
        <Typography tag="p" color="white.default" variant="paragraph1" intlKey={`cms.securityPage.cardsArea[${index}].helpCards[2].description`} />
        <Link
          intlKey={`cms.securityPage.cardsArea[${index}].helpCards[2].ctaTitle`}
          href={formatMessage({ id: `cms.securityPage.cardsArea[${index}].helpCards[2].ctaUrl` })}
          variant="action"
          className="help-card-cta"
          width="fit-content"
          typographyProps={{
            color: 'white.default',
          }}
        />
      </Box>
      <Box
        backgroundColor="black.default"
        borderRadius="25px"
        className="help-card"
        height="100%"
        width={{ xs: '330px', lg: '380px' }}
        padding="6x"
        display="flex"
        flexDirection="column"
        margin="auto"
        gridGap="6x"
        gridColumn={{ md: '2', lg: '3' }}
        gridRow={{ md: ' 7 / 11', lg: '4 / 11' }}
      >
        <Box
          height="50px"
          width="50px"
          borderRadius="50%"
          backgroundColor="white.default"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Email aria-hidden="true" />
        </Box>
        <Typography
          tag="h4"
          className="help-card-title"
          color="white.default"
          variant="heading4"
          intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].title`}
        />
        <Box>
          <Typography
            tag="p"
            color="white.default"
            width="50%"
            variant="paragraph1"
            intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].telText`}
          />
          <Typography
            tag="p"
            className="help-card-number"
            color="white.default"
            variant="heading4"
            intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].telNumber`}
          />
        </Box>
        <Box>
          <Typography tag="p" color="white.default" width="70%" variant="paragraph1" intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].telText2`} />
          <Typography
            tag="p"
            className="help-card-number"
            color="white.default"
            variant="heading4"
            intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].telNumber2`}
          />
        </Box>
        <Link
          intlKey={`cms.securityPage.cardsArea[${index}].helpCards[3].ctaTitle`}
          href={formatMessage({ id: `cms.securityPage.cardsArea[${index}].helpCards[3].ctaUrl` })}
          variant="action"
          className="help-card-cta"
          width="fit-content"
          typographyProps={{
            color: 'white.default',
          }}
        />
      </Box>
    </StyledBox>
  );
}

SectionHelpCards.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionHelpCards;
