import React from 'react';

export function Warning(props) {
  return (
    <svg width="66" height="64" viewBox="0 0 66 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M35.75 40V45.3334H30.25V40H35.75Z" fill="white" />
      <path d="M35.75 37.3334V18.6667H30.25V37.3334H35.75Z" fill="white" />
      <path d="M33 58.6667C48.1878 58.6667 60.5 46.7276 60.5 32C60.5 17.2724 48.1878 5.33337 33 5.33337C17.8122 5.33337 5.5 17.2724 5.5 32C5.5 46.7276 17.8122 58.6667 33 58.6667ZM33 53.3334C20.8497 53.3334 11 43.7821 11 32C11 20.218 20.8497 10.6667 33 10.6667C45.1503 10.6667 55 20.218 55 32C55 43.7821 45.1503 53.3334 33 53.3334Z" fill="white" />
    </svg>

  );
}
