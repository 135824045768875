import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Button from '@nubank/nuds-web/components/Button/Button';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import Image from '@nubank/www-latam-commons/components/Image/Image';

import { Play } from './components/icons/Play';
import { Security } from './components/icons/Security';
import { Phone } from './components/icons/Phone';
import { StyledBox, StyledIcon } from './styles/Styled';
import Modal from './components/Modal/Modal';

function SectionSOS({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const sosImages = formatMessage({ id: `cms.securityPage.cardsArea[${index}].content` });
  const sosVideoImages = formatMessage({ id: `cms.securityPage.cardsArea[${index}].imageVideo` });
  const faqQuestions = formatMessage({ id: `cms.securityPage.cardsArea[${index}].cards` });
  const videoUrl = formatMessage({ id: `cms.securityPage.cardsArea[${index}].urlVideo` });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const imageSrcSet = generateDPRsForCMSImage([
    {
      imageMobile: {
        url: sosImages[0].imageMobile.url,
      },
      imageTablet: {
        url: sosImages[0].imageTablet.url,
      },
      imageDesktop: {
        url: sosImages[0].imageDesktop.url,
      },
    },
  ]);

  const thumbSrcSet = generateDPRsForCMSImage([
    {
      imageMobile: {
        url: sosVideoImages[0].imageMobile.url,
      },
      imageTablet: {
        url: sosVideoImages[0].imageTablet.url,
      },
      imageDesktop: {
        url: sosVideoImages[0].imageDesktop.url,
      },
    },
  ]);

  const IconsMap = {
    Phone,
    Security,
  };

  function openModal() {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Grid
        row
        tag="section"
        spacingHorizontal="2x"
        spacingVertical={{
          xs: '16x',
          md: '20x',
        }}
      >
        <Grid col={{ lg: 10 }} offset={{ lg: 1 }}>
          <Image
            loading="lazy"
            width={{ xs: '275px', md: '400px' }}
            objectFit="cover"
            srcSet={imageSrcSet}
            altIntlKey={`cms.securityPage.cardsArea[${index}].content[0].imageMobile.alt`}
          />
          <Typography
            variant="subtitle1"
            tag="p"
            color="black"
            marginBottom={{ xs: '8x', md: '12x' }}
            marginTop={{ xs: '6x', lg: '12x' }}
            whiteSpace="pre-line"
            maxWidth="950px"
            paddingLeft={{ xs: '0', md: '4x' }}
            intlKey={`cms.securityPage.cardsArea[${index}].subtitle`}
          />
          <StyledBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            width={{ xs: '312px', md: '640px', lg: '815px' }}
            height={{ xs: '175px', md: '360px', lg: '460px' }}
            backgroundImage={thumbSrcSet}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            margin="40px auto"
          >
            <Play onClick={() => openModal()} />
          </StyledBox>
          <Link
            className="scam-button"
            href={formatMessage({ id: `cms.securityPage.cardsArea[${index}].ctaLink` })}
            color="white"
            margin="50px auto"
            display="block"
            width="fit-content"
          >
            <Button
              intlKey={`cms.securityPage.cardsArea[${index}].ctaText`}
              iconProps={{ name: 'arrow-up-right' }}
              marginTop="12x"
            />
          </Link>
          <Typography
            variant="subtitle1"
            tag="p"
            color="black"
            marginBottom="8x"
            marginTop={{ xs: '6x', lg: '12x' }}
            whiteSpace="pre-line"
            paddingLeft={{ xs: '0', md: '4x' }}
            maxWidth="950px"
            intlKey={`cms.securityPage.cardsArea[${index}].subtitle2`}
          />
          <Box paddingLeft={{ xs: '0', md: '4x' }}>
            {faqQuestions.map(({ id, icon }, itemIndex) => {
              const IconComponent = IconsMap[icon];
              return (
                <Box
                  key={id}
                  backgroundColor="primary.default"
                  padding="4x"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap="20px"
                  borderRadius={{ xs: '25px', md: '35px', lg: '200px' }}
                  marginBottom="6x"
                >
                  {IconComponent && (
                    <StyledIcon
                      width={{ xs: '45px', md: '80px' }}
                      height={{ xs: '40px', md: '75px' }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      backgroundColor="white.default"
                    >
                      <IconComponent aria-hidden="true" />
                    </StyledIcon>
                  )}
                  {formatMessage(
                    {
                      id: `cms.securityPage.cardsArea[${index}].cards[${itemIndex}].description`,
                    },
                    {
                      strong: props => (
                        <Typography tag="strong" variant="subtitle1" strong {...props} />
                      ),
                      p: props => (
                        <Typography width="100%" variant="subtiitle1" color="white" {...props} />
                      ),
                      a: props => <Link variant="action" {...props} />,
                    },
                  )}
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Modal videoId={videoUrl} closeModal={closeModal} isOpen={modalIsOpen} />
      </Grid>
    </>
  );
}

SectionSOS.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionSOS);
