import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FAQPageJsonLd } from 'next-seo';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import sanitizer from '../../../../utils/sanitizer';

import { Lock } from './components/icons/Lock';
import { StyledList, StyledListItem, StyledTitle } from './styles/List';

function SectionFAQ({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  const faqQuestions = formatMessage({ id: `cms.securityPage.cardsArea[${index}].faqItems` });

  return (
    <>
      <Grid
        row
        id="faq"
        tag="section"
        spacingHorizontal="2x"
        spacingVertical={{
          xs: '16x',
          md: '20x',
          lg: '32x',
        }}
      >
        <Grid col={{ lg: 10 }} offset={{ lg: 1 }}>
          <StyledTitle
            display="flex"
            alignItems="center"
            marginBottom="8x"
            gridGap={{ xs: '12px', md: '25px' }}
          >
            <Box
              width={{ xs: '48px', md: '85px' }}
              height={{ xs: '48px', md: '85px' }}
              backgroundColor="primary.default"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
            >
              <Lock aria-hidden="true" />
            </Box>
            <Typography
              variant="heading2"
              color="black"
              colorVariant="default"
              intlKey={`cms.securityPage.cardsArea[${index}].title`}
            />
          </StyledTitle>
          <Typography
            variant="subtitle1"
            tag="p"
            color="black"
            colorVariant="default"
            marginBottom="16x"
            intlKey={`cms.securityPage.cardsArea[${index}].ctaText`}
          />
          <StyledList>
            {faqQuestions.map(({ id, title }, faqQuestionIndex) => (
              <StyledListItem key={id}>
                <Collapse id={`pix-faq-${id}`}>
                  <Collapse.Header>
                    <Typography
                      color="black"
                      colorVariant="light"
                      marginBottom="2x"
                      variant="heading4"
                    >
                      {title}
                    </Typography>
                  </Collapse.Header>
                  <Collapse.Body>
                    {formatMessage(
                      {
                        id: `cms.securityPage.cardsArea[${index}].faqItems[${faqQuestionIndex}].content`,
                      },
                      {
                        strong: props => (
                          <Typography tag="strong" variant="subtitle1" strong {...props} />
                        ),
                        p: props => <Typography marginBottom="5x" {...props} />,
                        a: props => <Link variant="action" {...props} />,
                      },
                    )}
                  </Collapse.Body>
                </Collapse>
              </StyledListItem>
            ))}
          </StyledList>
        </Grid>
      </Grid>
      <FAQPageJsonLd
        mainEntity={faqQuestions.map(item => ({
          questionName: sanitizer.forJSON(item.title),
          acceptedAnswerText: sanitizer.forJSON(item.content),
        }))}
      />
    </>
  );
}

SectionFAQ.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionFAQ);
