import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledList = styled.ul`
  width: 100%;
`;

export const StyledTitle = styled(Box)`
  svg {
    ${breakpointsMedia({
    md: 'width: 60px; height: 60px;',
  })}
  }
`;

StyledList.displayName = 'StyledList';

export const StyledListItem = styled.li`
  & + & {
    margin-top: 4rem;
  }
`;

StyledListItem.displayName = 'StyledListItem';
