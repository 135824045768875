import React from 'react';

export function Security(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <title>Icone de segurança</title>
      <path d="M9.16683 5.83317H10.8335V10.8332H9.16683V5.83317Z" fill="#820AD1" />
      <path d="M9.16683 12.4998H10.8335V14.1665H9.16683V12.4998Z" fill="#820AD1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.438 18.4133C9.62183 18.5232 9.80925 18.6353 10.0002 18.7498C10.1911 18.6353 10.3785 18.5232 10.5623 18.4133C14.3907 16.1241 16.6668 14.763 16.6668 11.2498V4.99984L10.0002 1.6665L3.3335 4.99984V11.2498C3.3335 14.763 5.60965 16.1241 9.438 18.4133ZM15.0002 11.2498C15.0002 12.6084 14.5814 13.4331 13.7405 14.2419C12.9011 15.0494 11.7073 15.7835 10.0002 16.8069C8.29304 15.7835 7.09921 15.0494 6.25978 14.2419C5.41896 13.4331 5.00016 12.6084 5.00016 11.2498V6.02989L10.0002 3.52989L15.0002 6.02989V11.2498Z"
        fill="#820AD1"
      />
    </svg>
  );
}
