import React from 'react';

export function Lock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 31 30"
      fill="none"
      {...props}
    >
      <title>Cadeado</title>
      <path d="M14.5326 21.0525L14.5326 18.6103H17.0208V21.0525H14.5326Z" fill="white" />
      <path
        d="M21.9973 12.505H25.7296V22.2736C25.7296 24.9711 23.5016 27.1579 20.7531 27.1579H10.8002C8.05177 27.1579 5.82373 24.9711 5.82373 22.2736V12.505H9.55608V8.84172C9.55608 5.52682 12.333 2.73633 15.7767 2.73633C19.2203 2.73633 21.9973 5.52682 21.9973 8.84172V12.505ZM12.0443 8.84172V12.505H19.509V8.84172C19.509 6.85965 17.8299 5.17848 15.7767 5.17848C13.7234 5.17848 12.0443 6.85965 12.0443 8.84172ZM20.7531 24.7157C22.1274 24.7157 23.2414 23.6223 23.2414 22.2736V14.9471H8.31197V22.2736C8.31197 23.6223 9.42599 24.7157 10.8002 24.7157H20.7531Z"
        fill="white"
      />
    </svg>
  );
}
