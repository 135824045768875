import Link from '@nubank/nuds-web/components/Link/Link';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

import img from 'public/images/security/hero-security.gif';

export const StyledButton = styled(Link)`
  &:focus{
    outline: none
  }
`;

export const StyledBox = styled(Box)`
  background-image: url(${img});
`;
