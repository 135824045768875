import React, { useContext } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Link from '@nubank/nuds-web/components/Link/Link';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/components/pagination/pagination.min.css'; // eslint-disable-line
import 'swiper/components/navigation/navigation.min.css'; // eslint-disable-line
import 'swiper/swiper-bundle.css';

import { StyledTitle, StyledSection } from './styles/Styled';
import { Warning } from './components/Warning';

SwiperCore.use([Navigation, Pagination]);

function SectionScamCarousel({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const scamCards = formatMessage({ id: `cms.securityPage.cardsArea[${index}].carouselItens` });

  return (
    <StyledSection tag="section" paddingTop={{ xs: '16x', md: '20x' }}>
      <StyledTitle display="flex" justifyContent="space-between" gridGap="10px">
        <Typography
          className="scam-title"
          color="primary.default"
          marginTop="2x"
          variant="heading2"
          width="70%"
          paddingLeft={{ xs: '6x', lg: '32x' }}
          paddingRight={{ xs: '8x', md: '8x' }}
          paddingVertical="6x"
          intlKey={`cms.securityPage.cardsArea[${index}].title`}
        />
        <Box
          width="120px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="auto"
          backgroundColor="primary.default"
          borderRadius="120px 0 0 120px"
          className="scam-detail"
        >
          <Warning aria-hidden="true" />
        </Box>
      </StyledTitle>
      <Typography
        variant="subtitle1"
        tag="p"
        color="black"
        width={{ xs: '90%', md: '70%' }}
        marginTop={{ xs: '12x', lg: '18x' }}
        paddingLeft={{ xs: '6x', lg: '32x' }}
        intlKey={`cms.securityPage.cardsArea[${index}].subtitle`}
      />

      <Box paddingVertical={{ xs: '12x', md: '14x', lg: '16x' }}>
        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          navigation
          pagination={{
            clickable: true,
          }}
        >
          {scamCards.map(({ id, title, subtitle }, indexCards) => (
            <SwiperSlide key={id}>
              <Box
                padding="6x"
                border="2px solid"
                borderColor="primary.default"
                borderRadius={25}
                display="flex"
                flexDirection="column"
                marginTop="4x"
                height="275px"
                width={{ xs: '290px', md: '375px' }}
              >
                <Box display="flex" alignItems="center" gridGap="15px">
                  <Box
                    tag="div"
                    borderRadius="50%"
                    width="40px"
                    minHeight="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="primary.default"
                  >
                    <Typography tag="p" color="white.default" variant="heading4">
                      {indexCards + 1}
                    </Typography>
                  </Box>
                  <Typography marginTop="2x" width="80%" variant="heading4">
                    {title}
                  </Typography>
                </Box>
                <Typography marginTop="4x" variant="subtitle1">
                  {subtitle}
                </Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Link
          className="scam-button"
          href={formatMessage({ id: `cms.securityPage.cardsArea[${index}].ctaUrl` })}
          color="white"
        >
          <Button
            intlKey={`cms.securityPage.cardsArea[${index}].cta`}
            iconProps={{ name: 'arrow-up-right' }}
            marginTop="12x"
          />
        </Link>
      </Box>
    </StyledSection>
  );
}

SectionScamCarousel.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionScamCarousel);
