import React from 'react';

export function Chat(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path d="M9.87492 11.0476H7.79158V13.1248H9.87492V11.0476Z" fill="#820AD1" />
      <path d="M14.0416 11.0476H11.9583V13.1248H14.0416V11.0476Z" fill="#820AD1" />
      <path d="M16.1249 11.0476H18.2083V13.1248H16.1249V11.0476Z" fill="#820AD1" />
      <path
        d="M17.1666 20.395L23.4166 23.5109V7.93177C23.4166 5.63734 21.5511 3.77734 19.2499 3.77734H6.74992C4.44873 3.77734 2.58325 5.63734 2.58325 7.93177V16.2406C2.58325 18.535 4.44873 20.395 6.74992 20.395H17.1666ZM21.3333 20.1499L17.6584 18.3178H6.74992C5.59933 18.3178 4.66659 17.3878 4.66659 16.2406V7.93177C4.66659 6.78455 5.59932 5.85456 6.74992 5.85456H19.2499C20.4005 5.85456 21.3333 6.78455 21.3333 7.93177V20.1499Z"
        fill="#820AD1"
      />
    </svg>
  );
}
