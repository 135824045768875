import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css'; // eslint-disable-line
import 'swiper/components/navigation/navigation.min.css'; // eslint-disable-line
import 'swiper/swiper-bundle.css';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { StyledCarousel } from './Styles/Styled';

SwiperCore.use([Navigation, Pagination]);

function SectionInformativeCarousel({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const informativeCards = formatMessage({
    id: `cms.securityPage.cardsArea[${index}].informativeCards`,
  });

  return (
    <StyledCarousel
      tag="section"
      paddingVertical={{ xs: '8x', md: '16x' }}
      backgroundColor="white.dark"
    >
      <Typography
        variant="heading2"
        paddingRight={{ xs: '6x', lg: '32x' }}
        paddingLeft={{ xs: '6x', lg: '32x' }}
        marginBottom={{ xs: '8x', md: '12x' }}
        width={{ md: '800px' }}
        intlKey={`cms.securityPage.cardsArea[${index}].title`}
      />
      <Typography
        variant="subtitle1"
        paddingRight={{ xs: '6x', lg: '32x' }}
        paddingLeft={{ xs: '6x', lg: '32x' }}
        marginBottom={{ xs: '8x', md: '12x' }}
        intlKey={`cms.securityPage.cardsArea[${index}].subtitle`}
      />
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        navigation
        pagination={{
          clickable: true,
        }}
      >
        {informativeCards.map(({
          id, text, cta, ctaurl,
        }) => (
          <SwiperSlide key={id}>
            <Box
              className="slide-card"
              tag="a"
              width={{ xs: '290px', md: '375px' }}
              height="235px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              backgroundColor="primary.default"
              borderRadius="30px"
              border="solid 2px transparent"
              padding="6x"
              href={ctaurl}
              target="_blank"
            >
              <Typography tag="h4" variant="subtitle1" color="white.default">
                {text}
              </Typography>
              <Link
                variant="action"
                typographyProps={{ color: 'white.default' }}
                href={ctaurl}
                width="fit-content"
              >
                {cta}
              </Link>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledCarousel>
  );
}

SectionInformativeCarousel.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionInformativeCarousel;
