import styled from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Image from '@nubank/www-latam-commons/components/Image/Image';

export const StyledImage = styled(Image)`
  ${breakpointsMedia({
    xs: 'border-top-right-radius: 60px;',
    md: 'border-top-right-radius: 150px;',
    lg: 'border-top-right-radius: 200px;',
  })}
`;
