import React from 'react';
import YouTube from 'react-youtube';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';

import { StyledModal } from './Styled/Styled';

/* eslint-disable react/prop-types */

const Modal = ({ isOpen, videoId, closeModal }) => {
  const modalStyle = {
    display: isOpen ? 'flex' : 'none',
  };

  const opts = {
    height: '100%',
    width: '100%',
  };

  return (
    <StyledModal
      position="fixed"
      top="0"
      left="0"
      bottom="0"
      padding={{ xs: '1x', lg: '8x' }}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      zIndex="9"
      backgroundColor="black.default"
      style={modalStyle}
    >
      <Button
        variant="contained"
        styleVariant="primary"
        iconProps={{ name: 'x', title: 'Botão de fechar o modal' }}
        className="modal-close"
        onClick={closeModal}
      />
      <Box
        position="relative"
        width="90%"
        maxHeight="90%"
        height="auto"
        overflow="hidden"
        paddingBottom="50%"
      >
        {isOpen && (<YouTube videoId={videoId} opts={opts} />)}
      </Box>
    </StyledModal>
  );
};

export default Modal;
