import React, { useContext } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import PropTypes from 'prop-types';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

import { StyledImage } from './styles/Styled';

function SectionDigitalSecurity({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const securityCardsArea = formatMessage({ id: `cms.securityPage.cardsArea[${index}]` });

  const imageSrcSet = generateDPRsForCMSImage([
    {
      imageMobile: {
        url: securityCardsArea.image[0].imageMobile.url,
      },
      imageTablet: {
        url: securityCardsArea.image[0].imageTablet.url,
      },
      imageDesktop: {
        url: securityCardsArea.image[0].imageDesktop.url,
      },
    },
  ]);

  return (
    <Box
      tag="section"
      display="flex"
      backgroundColor="white.dark"
      paddingBottom={{ xs: '0', md: '6x' }}
      borderRadius={{ xs: '0px 68px 0 0', md: '0px 150px 0 0', lg: '0px 200px 0 0' }}
      className="SectionDigitalSecurity"
      id="digital-security"
    >
      <Grid
        gridTemplateRows={{ md: 'repeat(2, auto)', lg: '1fr' }}
        rowGap="2x"
        paddingLeft={{ xs: '4x', md: '6x' }}
        paddingRight={{ xs: '4x', md: '6x' }}
        paddingTop={{ xs: '4x', md: '6x' }}
      >
        <Grid.Child
          gridColumn={{ xs: '1 / -1', lg: '1 / 7' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ xs: '2' }}
        >
          <Typography
            tag="h2"
            color="black"
            marginBottom="4x"
            paddingHorizontal={{ xs: '2x', lg: '100px' }}
            marginTop={{ xs: '4x', lg: '0' }}
            maxWidth={{ xs: '95%', lg: '800px' }}
            variant="heading2"
            intlKey={`cms.securityPage.cardsArea[${index}].title`}
          />
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / -1', lg: '7 / 10' }}
          alignSelf={{ lg: 'center' }}
          gridRow={{ xs: '3', lg: '2' }}
        >
          <Typography
            variant="subtitle1"
            tag="p"
            color="black"
            marginBottom="6x"
            marginTop={{ xs: '6x', lg: '24x' }}
            whiteSpace="pre-line"
            maxWidth="370px"
            paddingLeft={{ xs: '2x', md: '0' }}
            intlKey={`cms.securityPage.cardsArea[${index}].subtitle1`}
          />

          <Typography
            variant="subtitle1"
            tag="p"
            fontWeight="normal"
            color="black"
            whiteSpace="pre-line"
            maxWidth="370px"
            marginBottom="24x"
            marginTop="4x"
            paddingLeft={{ xs: '2x', md: '0' }}
            intlKey={`cms.securityPage.cardsArea[${index}].subtitle2`}
          />
        </Grid.Child>
        <Grid.Child gridColumn={{ xs: '1 /  -1 ' }} gridRow="1" alignSelf={{ lg: 'center' }}>
          <StyledImage
            loading="lazy"
            height={{ xs: '200px', md: '400px', lg: '460px' }}
            objectFit="cover"
            srcSet={imageSrcSet}
            altIntlKey={`cms.securityPage.cardsArea[${index}].image[0].imageDesktop.alt`}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionDigitalSecurity.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionDigitalSecurity;
