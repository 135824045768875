import React from 'react';

export function Email(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path
        d="M2.58325 8.52991C2.58325 6.23549 4.44873 4.37549 6.74992 4.37549H19.2499C21.5511 4.37549 23.4166 6.23549 23.4166 8.52991V16.8388C23.4166 19.1332 21.5511 20.9932 19.2499 20.9932H6.74992C4.44873 20.9932 2.58325 19.1332 2.58325 16.8388V8.52991ZM6.66236 6.4545L12.9999 12.7734L19.3375 6.4545C19.3084 6.4533 19.2792 6.4527 19.2499 6.4527H6.74992C6.72059 6.4527 6.6914 6.4533 6.66236 6.4545ZM4.87935 7.61435C4.74311 7.89055 4.66659 8.20132 4.66659 8.52991V16.8388C4.66659 17.986 5.59933 18.916 6.74992 18.916H19.2499C20.4005 18.916 21.3333 17.986 21.3333 16.8388V8.52991C21.3333 8.20132 21.2567 7.89055 21.1205 7.61435L13.7365 14.9766C13.3297 15.3822 12.6701 15.3822 12.2633 14.9766L4.87935 7.61435Z"
        fill="#820AD1"
      />
    </svg>
  );
}
