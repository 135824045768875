import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

export const StyledBox = styled(Box)`
  .help-card {
    &-email{
      text-decoration: underline;
    } 

    &-cta svg {
      color: #D2A5FF;
    }
  }
`;
