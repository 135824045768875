import React from 'react';

export function Arrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none" {...props}>
      <circle cx="18.5" cy="18.5" r="17.5" fill="white" stroke="#820AD1" />
      <path d="M27.679 19.321L19.1672 27.8328C18.962 28.038 18.6836 28.1533 18.3934 28.1533C18.1032 28.1533 17.8248 28.038 17.6196 27.8328L9.10776 19.321L10.6554 17.7734L17.2991 24.4171L17.2991 8.48772L19.4877 8.48772L19.4877 24.4171L26.1314 17.7734L27.679 19.321Z" fill="#820AD1" />
    </svg>
  );
}
