import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledModal = styled(Box)`
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: auto;

    ${breakpointsMedia({
    xs: ' height: 100%;',
    lg: 'height: 90%; padding: 20px;',
  })}
  }
`;
