import styled from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const StyledCarousel = styled(Box)`
  .swiper-pagination-bullet {
    background: ${nuDSColor('primary', 'defaultL20')};
    opacity: 1;

    &-active {
      background: ${nuDSColor('primary')};
      width: 20px;
      border-radius: 20px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${nuDSColor('primary')};
    top: unset;
    bottom: -4px;
    margin: 0 auto;
    z-index: 15;

    &::after {
      font-size: 20px;
    }
  }

  .swiper-button-prev {
    right: 0;
    left: -200px;
  }

  .swiper-button-next {
    left: 0;
    right: -200px;
  }

  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-bottom: 35px;
  }

  .swiper-slide {
    display: block;
    ${breakpointsMedia({
    xs: 'width: 295px;',
    md: 'width: 380px;',
  })}

    &:first-child {
      ${breakpointsMedia({
    xs: 'margin-left: 18px;',
    md: 'margin-left: 24px;',
    lg: 'margin-left: 128px;',
  })}
    }
  }

  .swiper-container {
    padding-bottom: 50px;
  }

  .slide-card:hover {
    background-color: ${nuDSColor('white', 'dark')};
    border: solid 2px ${nuDSColor('primary')};
  }

  .slide-card:hover h4,
  .slide-card:hover a {
    color: ${nuDSColor('primary')};
  }
`;
