import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { StyledButton, StyledBox } from './Styles/Styled';
import { Arrow } from './icons/Arrow';

function HeroSection({ index }) {
  return (
    <>
      <Box
        tag="section"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        minHeight="calc(100vh - 56px)"
      >
        <StyledBox
          backgroundColor="primary.default"
          width={{ lg: '50%' }}
          minHeight={{ xs: '50vh', lg: '100%' }}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        />
        <Box width={{ lg: '50%' }} margin="auto" paddingVertical="8x" paddingHorizontal="4x">
          <Typography
            variant="heading2"
            tag="h1"
            color="black.default"
            marginBottom="8x"
            paddingHorizontal={{ xs: '2x', md: '6x', lg: '8x' }}
            intlKey={`cms.securityPage.cardsArea[${index}].heroTitle`}
          />
          <Typography
            variant="subtitle1"
            color="black.default"
            marginBottom="8x"
            tag="p"
            paddingHorizontal={{ xs: '2x', md: '6x', lg: '8x' }}
            intlKey={`cms.securityPage.cardsArea[${index}].heroSubtitle`}
          />
          <StyledButton
            paddingHorizontal={{ xs: '2x', md: '6x', lg: '8x' }}
            href="#digital-security"
            onClick={e => {
              e.preventDefault();
              document
                .getElementById('digital-security')
                .scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }}
          >
            <Arrow aria-hidden="true" />
          </StyledButton>
        </Box>
      </Box>
    </>
  );
}

HeroSection.propTypes = {
  index: PropTypes.number.isRequired,
};

export default HeroSection;
