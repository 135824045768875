import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Image from '@nubank/www-latam-commons/components/Image/Image';

export const StyledImage = styled(Image)`
  ${breakpointsMedia({
    xs: 'border-top-right-radius: 68px;',
    md: 'border-top-right-radius: 150px;',
    lg: 'border-top-right-radius: 200px;',
  })}
`;

export const StyledBox = styled(Box)`
  .swiper-container {
    padding-top: 30px;
    padding-bottom: 130px;
  }

    .swiper-slide {
      ${breakpointsMedia({
    xs: 'width: 290px; height:auto;',
    md: 'width: 580px;',
    lg: 'width: 825px;',
  })}
    svg{
      cursor: pointer;
      
      &:hover{
        transform: scale(1.1);
      }

      ${breakpointsMedia({
    md: 'width: 140px; height: 140px',
  })}
    }
    }

    .swiper-pagination {
      top: unset;
      bottom: -2px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: unset;
      bottom: -15px;
      color: white;
      z-index: 15;

      &::after {
        font-size: 15px;
      }
    }

    .swiper-slide .overlay {
      position: absolute;
      background: #010101b5;
      left: 0;
      right: 0;
      width: 191px;
      height: 100%;
      z-index: 9;
      border-radius: 16px;
      ${breakpointsMedia({
    xs: 'width: 280px;',
    md: 'width: 560px;',
    lg: 'width: 815px;',
  })}
    }

    .swiper-slide-active {
      transform: scale(1.05);

      .overlay {
        display: none;
      }
    }

    .swiper-button-prev {
      right: 0;
      left: -100px;
      margin: 0 auto;
    }

    .swiper-button-next {
      left: 0;
      right: -100px;
      margin: 0 auto;
    }

    .swiper-pagination-bullet {
      background: white;
      opacity: 1;

      &-active {
        background: black;
        width: 20px;
        border-radius: 20px;
      }
    }
  

  .video-button {
    margin-top: -90px;
    position: absolute;
    display: block;
    text-decoration: none;
    left: 0;
    right: 0;
    z-index: 9;

    button {
      display: block;
      margin: 0 auto;

      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
`;
