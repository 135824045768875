import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledBox = styled(Box)`
  svg {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    ${breakpointsMedia({
    md: 'width: 140px; height: 140px',
  })}
  }
`;

export const StyledIcon = styled(Box)`
  svg {
    ${breakpointsMedia({
    md: 'width: 40px; height: 40px',
  })}
  }
`;

