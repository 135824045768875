import React, { useContext } from 'react';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import websitePage from 'hoc/websitePage/websitePage';

import SectionDigitalSecurity from './patterns/SectionDigitalSecurity/SectionDigitalSecurity';
import SectionScamCarousel from './patterns/SectionScamCarousel/SectionScamCarousel';
import SectionVideos from './patterns/SectionVideos/SectionVideos';
import HeroSection from './patterns/HeroSection/HeroSection';
import SectionInformativeCarousel from './patterns/SectionInformativeCarousel/SectionInformativeCarousel';
import SectionHelpCards from './patterns/SectionHelpCards/SectionHelpCards';
import SectionFAQ from './patterns/SectionFAQ/SectionFAQ';
import SectionSOS from './patterns/SectionSOS/SectionSOS';

function SecurityPage() {
  const { formatMessage } = useContext(NuDsContext);
  const securityCardsArea = formatMessage({ id: 'cms.securityPage.cardsArea' });

  const CARDS_MAP = {
    HeroSection,
    SectionDigitalSecurity,
    SectionVideos,
    SectionScamCarousel,
    SectionFAQ,
    SectionInformativeCarousel,
    SectionSOS,
    SectionHelpCards,
  };

  return (
    <>
      {securityCardsArea?.length
        && securityCardsArea.map((securityCard, index) => {
          const CardComponent = CARDS_MAP[securityCard.componentName];

          if (!CardComponent) return null;

          return <CardComponent key={securityCard.id} index={index} />;
        })}
    </>
  );
}

export default websitePage({
  routeKey: 'SEGURANCA',
})(SecurityPage);
