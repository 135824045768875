import React from 'react';

export function Eye(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path
        d="M3.46312 14.264L3.5745 14.089C5.5651 10.9601 7.78194 7.47565 13.0004 7.47565C18.2189 7.47565 20.4357 10.9601 22.4263 14.089L22.5377 14.264L24.2965 13.1506C24.234 13.0525 24.1708 12.9528 24.1066 12.8516C22.1676 9.79538 19.378 5.39844 13.0004 5.39844C6.62278 5.39844 3.83322 9.79539 1.89424 12.8516C1.8301 12.9527 1.76672 13.0526 1.70435 13.1506L3.46312 14.264Z"
        fill="#820AD1"
      />
      <path
        d="M17.1671 13.7073C17.1671 16.0017 15.3016 17.8617 13.0004 17.8617C10.6992 17.8617 8.83373 16.0017 8.83373 13.7073C8.83373 11.4129 10.6992 9.55286 13.0004 9.55286C15.3016 9.55286 17.1671 11.4129 17.1671 13.7073ZM15.0837 13.7073C15.0837 12.5601 14.151 11.6301 13.0004 11.6301C11.8498 11.6301 10.9171 12.5601 10.9171 13.7073C10.9171 14.8545 11.8498 15.7845 13.0004 15.7845C14.151 15.7845 15.0837 14.8545 15.0837 13.7073Z"
        fill="#820AD1"
      />
    </svg>
  );
}
