import React from 'react';

export function Phone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <title>Icone de telefone</title>
      <path
        d="M5.44088 8.66581L8.97642 5.13028L6.61939 2.77326L5.4258 3.96686C3.47879 5.91386 2.9961 8.88831 4.2275 11.3511L4.41847 11.733C4.83203 12.5602 5.37624 13.3152 6.03014 13.9691C6.68404 14.623 7.43908 15.1672 8.2662 15.5808L8.64815 15.7718C11.1109 17.0031 14.0854 16.5205 16.0324 14.5735L17.226 13.3799L14.869 11.0228L11.3334 14.5584L5.44088 8.66581ZM10.1549 6.30879L7.7979 8.66581L11.3334 12.2013L13.6905 9.84433C14.3413 9.19345 15.3966 9.19345 16.0475 9.84433L18.4664 12.2633C19.0831 12.8799 19.0831 13.8798 18.4664 14.4964L17.2109 15.752C14.7566 18.2062 11.0072 18.8147 7.9028 17.2625L7.52085 17.0715C6.5335 16.5778 5.63219 15.9282 4.85163 15.1476C4.07106 14.3671 3.42143 13.4658 2.92776 12.4784L2.73679 12.0965C1.18457 8.99202 1.79301 5.24262 4.24728 2.78834L5.50281 1.53281C6.11948 0.916145 7.1193 0.916145 7.73597 1.53281L10.1549 3.95177C10.8058 4.60264 10.8058 5.65792 10.1549 6.30879Z"
        fill="#820AD1"
      />
    </svg>
  );
}
