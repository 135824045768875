import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const StyledSection = styled(Box)`
  .scam-button {
    display: block;
    width: 300px;
    margin: 50px auto 0;

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  .swiper-slide {
    ${breakpointsMedia({
    xs: 'width: 295px;',
    md: 'width: 380px;',
  })}
    &:first-child {
      ${breakpointsMedia({
    xs: 'margin-left: 18px;',
    md: 'margin-left: 24px;',
    lg: 'margin-left: 128px;',
  })}
    }
  }

  .swiper-container {
    padding-bottom: 80px;
  }

  .swiper-pagination {
    top: unset;
    bottom: 12px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: unset;
    bottom: -1px;
    color: ${nuDSColor('primary')};
    z-index: 15;

    &::after {
      font-size: 20px;
    }
  }

  .swiper-button-prev {
    right: 0;
    left: -150px;
    margin: 0 auto;
  }

  .swiper-button-next {
    left: 0;
    right: -150px;
    margin: 0 auto;
  }

  .swiper-pagination-bullet {
    background: ${nuDSColor('primary', 'defaultL20')};
    opacity: 1;

    &-active {
      background: ${nuDSColor('primary')};
      width: 20px;
      border-radius: 20px;
    }
  }
`;

export const StyledBox = styled(Box)`
  ${breakpointsMedia({
    xs: 'border-top-right-radius: 68px;',
    md: 'border-top-right-radius: 150px;',
    lg: 'border-top-right-radius: 200px;',
  })}
`;

export const StyledTitle = styled(Box)`
  .scam-title {
    border: 2px solid;
    border-left: 0;
    border-radius: 0 120px 120px 0;
  }
`;
