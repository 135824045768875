import React from 'react';

export function Share(props) {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title />
      <path d="M12,9a2.98506,2.98506,0,0,0-2.07672.84357l-2.96-1.47992A2.94221,2.94221,0,0,0,7,8a2.94221,2.94221,0,0,0-.03674-0.36365l2.96-1.47992A2.994,2.994,0,1,0,9,4a2.95106,2.95106,0,0,0,.03674.36346l-2.96014,1.48a3,3,0,1,0,0,4.313l2.96014,1.48A2.95106,2.95106,0,0,0,9,12,3,3,0,1,0,12,9Zm0-6a1,1,0,1,1-1,1A1.001,1.001,0,0,1,12,3ZM4,9A1,1,0,1,1,5,8,1.001,1.001,0,0,1,4,9Zm8,4a1,1,0,1,1,1-1A1.001,1.001,0,0,1,12,13Z" fill="#820AD1" />
    </svg>
  );
}
