import React, { useEffect, useState, useContext } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@nubank/nuds-web/components/Box/Box';

import 'swiper/components/pagination/pagination.min.css'; // eslint-disable-line
import 'swiper/components/navigation/navigation.min.css'; // eslint-disable-line
import 'swiper/swiper-bundle.css';

import { StyledBox } from './styles/Styled';
import { Share } from './components/Share';
import { Play } from './components/Play';
import Modal from './components/Modal/Modal';

SwiperCore.use([Navigation, Pagination]);

function SectionVideos({ index }) {
  const [fullUrl, setFullUrl] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const { formatMessage } = useContext(NuDsContext);
  const videos = formatMessage({ id: `cms.securityPage.cardsArea[${index}].videos` });

  useEffect(() => {
    const url = window.location.href;
    setFullUrl(url);
  }, []);

  function openModal(videoId) {
    setVideoUrl(videoId);
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <StyledBox tag="section" id="videos" backgroundColor="primary.default" paddingVertical="18x">
      <Typography
        tag="h2"
        color="white"
        marginBottom="4x"
        paddingHorizontal={{ xs: '6x', lg: '32x' }}
        width={{ md: '70%' }}
        marginTop={{ xs: '4x', lg: '0' }}
        variant="heading2"
        intlKey={`cms.securityPage.cardsArea[${index}].title`}
      />
      <Typography
        variant="subtitle1"
        tag="p"
        color="white"
        marginBottom="12x"
        paddingHorizontal={{ xs: '6x', lg: '32x' }}
        width={{ md: '70%' }}
        marginTop={{ xs: '4x', lg: '8x' }}
        whiteSpace="pre-line"
        intlKey={`cms.securityPage.cardsArea[${index}].subtitle`}
      />
      <Swiper slidesPerView="auto" spaceBetween={38} navigation pagination centeredSlides>
        {videos.map(({ id, videoId, videoThumbnail }) => (
          <SwiperSlide key={id}>
            <div className="overlay" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              width={{ xs: '280px', md: '560px', lg: '815px' }}
              height={{ xs: '160px', md: '320px', lg: '460px' }}
              backgroundImage={videoThumbnail.url}
              backgroundSize="contain"
            >
              <Play onClick={() => openModal(videoId)} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Link
        className="video-button"
        href={`https://api.whatsapp.com/send?text=Saiba%20como%20se%20prevenir%20de%20golpes%20e%20fraudes%20com%20as%20dicas%20do%20Nubank%20${fullUrl}%23videos`}
        color="white"
      >
        <Button marginTop="8x" styleVariant="white">
          Compartilhar
          <Share aria-hidden="true" />
        </Button>
      </Link>
      <Modal videoId={videoUrl} closeModal={closeModal} isOpen={modalIsOpen} />
    </StyledBox>
  );
}

SectionVideos.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionVideos;
