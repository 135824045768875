import React from 'react';

export function SadFace(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path d="M10.9166 9.08002H8.83325V11.1572H10.9166V9.08002Z" fill="#820AD1" />
      <path d="M15.0833 9.08002H17.1666V11.1572H15.0833V9.08002Z" fill="#820AD1" />
      <path
        d="M12.9999 15.3117C11.274 15.3117 9.87492 16.7067 9.87492 18.4275H7.79158C7.79158 15.5594 10.1234 13.2344 12.9999 13.2344C15.8764 13.2344 18.2083 15.5594 18.2083 18.4275H16.1249C16.1249 16.7067 14.7258 15.3117 12.9999 15.3117Z"
        fill="#820AD1"
      />
      <path
        d="M23.4166 13.2344C23.4166 18.9705 18.7529 23.6205 12.9999 23.6205C7.24695 23.6205 2.58325 18.9705 2.58325 13.2344C2.58325 7.49838 7.24695 2.84839 12.9999 2.84839C18.7529 2.84839 23.4166 7.49838 23.4166 13.2344ZM21.3333 13.2344C21.3333 8.6456 17.6023 4.9256 12.9999 4.9256C8.39755 4.9256 4.66659 8.6456 4.66659 13.2344C4.66659 17.8233 8.39755 21.5433 12.9999 21.5433C17.6023 21.5433 21.3333 17.8233 21.3333 13.2344Z"
        fill="#820AD1"
      />
    </svg>
  );
}
